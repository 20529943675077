import { actionTypes } from "./actionTypes";
const initialAuthState = {
    eventBeingAdded: false,
    isScheduleFormVisible: false,
    goToEmailTemplateList: false,
    goToNotificationList: false,
    zonesEnabled: []
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.AddEventData: {
            return { 
                ...state,
                eventBeingAdded: true, 
                eventData: action.payload 
            };
        }
        case actionTypes.RemoveEventData: {
            return { ...state, eventBeingAdded: false, eventData: null };
        }
        case actionTypes.UpdateEventData: {
            return { ...state, eventBeingAdded: true, eventData: action.payload };
        }
        case actionTypes.SaveCurrentEventData: {
            return { ...state, eventData: action.payload }
        }
        case actionTypes.SetScheduleFormVisibility: {
            return { ...state, isScheduleFormVisible: !state.isScheduleFormVisible }
        }
        case actionTypes.SetScheduleFormData: {
            return { ...state, editingScheduleData: action.payload }
        }
        case actionTypes.SetEventTags: {
            return { ...state, tags: action.payload }
        }
        case actionTypes.SetEventSpeakers: {
            return { ...state, speakers: action.payload }
        }
        case actionTypes.SetZonesWithStatus: {
            return { ...state, zonesEnabled: action.payload }
        }
        case actionTypes.GoToEmailTemplateList: {
            return { ...state, goToEmailTemplateList: action.payload }
        }
        case actionTypes.GoToNotificationList: {
            return { ...state, goToNotificationList: action.payload }
        }
        default:
            return state;
    }
}