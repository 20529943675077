import axios from "axios";
import { API_BASE_URL } from "../../../constants";

export const LOGIN_URL = `${API_BASE_URL}auth/login`;
export const REGISTER_URL = `${API_BASE_URL}client/create`;
export const FORGOT_PASSWORD_URL = `${API_BASE_URL}auth/forgotPassword`;
export const RESET_PASSWORD_URL = `${API_BASE_URL}auth/resetPassword`;
export const SOCIAL_LOGIN_URL = `${API_BASE_URL}auth/login/social`;
export const VERIFY_TOKEN_URL = `${API_BASE_URL}client/validateToken`;
export const UPDATE_DETAILS_URL = `${API_BASE_URL}client/updateDetails`;
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}auth/me`;
export const UPDATE_USER_PROFILE_URL = `${API_BASE_URL}auth/me/update`;
export const UPDATE_USER_PASSWORD_URL = `${API_BASE_URL}auth/me/update/password`;
export const ORG_SUGGESTION_URL = `${API_BASE_URL}client/orgSuggestion`;
export const UPDATE_CLIENT_INTEREST = `${API_BASE_URL}client/about`;
export const CREATE_CLIENT_ORGANIZATION = `${API_BASE_URL}client/organization/create`;
export const UPDATE_CLIENT_ORGANIZATION = `${API_BASE_URL}client/organization/update`;
export const RESEND_EMAIL_URL = `${API_BASE_URL}client/resendEmail`;
export const HOT_EVENT_URL = `${API_BASE_URL}event/hotEvent`;
export const ALL_EVENT_LIST = `${API_BASE_URL}event/listEvent`;
export const LOGIN_WITH_OTP = `${API_BASE_URL}auth/login/otp`;
export const COUNTRY_CODE = `${API_BASE_URL}countryCode`;
export const COUNTRY_LIST = `${API_BASE_URL}countries`;



export function login(values) {
  return axios.post(LOGIN_URL, values);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

/**
 * register: register user
 * @param: email
 * sends a verification code to user's email id
 */
export function register(g_recaptcha_response, email) {
  return axios.post(REGISTER_URL, { g_recaptcha_response, email });
}

/**
 * verifyTempToken: verify user email id
 * @param: token
 * verifies user with temporary token
 */
export function verifyTempToken(email, token, g_recaptcha_response) {
  return axios.post(VERIFY_TOKEN_URL, { email, token, g_recaptcha_response });
}

/**
 * updateDetails: update details of user
 * @param: {email, token, first_name, last_name, password}
 * updates user details
 */
export function updateDetails(data) {
  return axios.post(UPDATE_DETAILS_URL, data);
}

/**
 * socialLogin: send social network token for login
 * @param: token
 * @param: socialDriver: social network name
 * 
 */
export function socialLogin(token, driver) {
  const apiData = typeof token === 'object' ? { ...token, driver } : { token, driver }
  return axios.post(SOCIAL_LOGIN_URL, apiData);
}

/**
 * orgSuggestion: get event options
 * @returns events options list
 */
export function orgSuggestion() {
  return axios.post(ORG_SUGGESTION_URL);
}

/**
 * Update interest 
 * @param: data: {purpose, interested_in:string[]}
 */
export function updateInterest(data) {
  return axios.post(UPDATE_CLIENT_INTEREST, data);
}

/**
 * Create event organization
 * @param {*} name organization name
 */
export function createOrganization(name) {
  return axios.post(CREATE_CLIENT_ORGANIZATION, name);
}

/**
 * Update the event organization
 * @param {*} event_type_to_host host type
 * @param {*} frequency_of_events event frequency
 */
export function updateOrganization(data) {
  return axios.post(UPDATE_CLIENT_ORGANIZATION, data);
}

/**
 * Method to resend token to user email
 * @param {*} email user email 
 * @returns token
 */
export function resendEmail (email, g_recaptcha_response) {
  return axios.post(RESEND_EMAIL_URL, email, g_recaptcha_response);
}

/**
 * Method to get the hot event list
 * @returns hot events list
 */
export function hotEventList(id) {
  return axios.post(HOT_EVENT_URL, {organization_id: id});
}
/*
 * @method: forgotPassword: method to trigger forgot password, 
 * sends a token to given email id
 * @param: email
 * @returns: promise
 */
export function forgotPassword(email, g_recaptcha_response) {
  return axios.post(FORGOT_PASSWORD_URL, {email, g_recaptcha_response})
}

/**
 * @method: resetPassword: method to reset password
 * @param: email
 * @param: token 
 * @param: password: new password
 * @returns: promise
 */
export function resetPassword(values, g_recaptcha_response) {
  return axios.post(RESET_PASSWORD_URL,{email: values.email, password: values.password, token: values.token, g_recaptcha_response});
}

/**
 * To get the List of events
 * @returns List of events
 */
export function listEvents(searchTerm, id) {
  if(searchTerm){
    return axios.post(`${ALL_EVENT_LIST}?search=${searchTerm}`,{organization_id: id});

  }else{
    return axios.post(ALL_EVENT_LIST, {organization_id: id});

  }
}

/**
 * @method : updateProfile | To update user profile
 * @param {*} values | values to be updated
 * @returns | Updated profile
 */
export function updateProfile(values) {
  return axios.post(UPDATE_USER_PROFILE_URL, values);
}

/**
 * @method : changePassword | To update user password
 * @param {*} values | Updated and old password
 * @returns | Promise
 */
export function changePassword(values) {
  return axios.post(UPDATE_USER_PASSWORD_URL, values);
}

/**
 * @method : loginWithOtp | login 
 * @param {*} values | login details
 * @returns | Promise
 */
export function loginWithOtp(values) {
  return axios.post(LOGIN_WITH_OTP, values);
}

export function getCountryCode() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(COUNTRY_CODE);
}

/**
 * @method : getCountryList | To get list of all countries
 * @returns | Country list
 */
export function getCountryList() {
  return axios.get(COUNTRY_LIST);
}