import { PureComponent } from 'react';
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import CropperModal from '../../../app/modules/common/CropperModal';

// const iconByFn = {
//     cancel: { width: '20px', height: '20px', backgroundColor: 'orange' }, //`url(${cancelImg})`
//     remove: { width: '20px', height: '20px', backgroundColor: 'red' }, //`url(${removeImg})`
//     restart: { width: '20px', height: '20px', backgroundColor: 'blue' }, //`url(${restartImg})`
// }

class Preview extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {croppedImage: undefined}
    }

    onRemove = () => {
        const { fileWithMeta: { cancel, remove, restart, xhr } } = this.props
        if (this.props.onDelete) {
            this.props.onDelete()
        }
        remove();
        this.props.setMakeUrl("")
        this.props.setIsImageCropper(false);
    }

    render() {
        const {
            className,
            imageClassName,
            style,
            imageStyle,
            fileWithMeta: { cancel, remove, restart, xhr },
            meta: { name = '', percent = 0, size = 0, previewUrl, status, duration, validationError, type },
            isUpload,
            canCancel,
            canRemove,
            canRestart,
            extra: { minSizeBytes },
        } = this.props
        const isVideo = type.includes('video');
        const isImage = type.includes('image');
        // const isCsv = type.includes('csv');
        let uploadedFileUrl = ''
        let uploadedFileId = ''
        if (isVideo && xhr) {
            const { responseText } = xhr;
            if (responseText) {
                const fileUploadResponseParsed = JSON.parse(responseText);
                if ((!previewUrl) && fileUploadResponseParsed.status === 'success') {
                    uploadedFileUrl = fileUploadResponseParsed.data.file_url;
                }
            }
        }

        if ((isVideo || isImage) && xhr) {
            const { responseText } = xhr;
            if (responseText) {
                const fileUploadResponseParsed = JSON.parse(responseText);
                if (fileUploadResponseParsed.status === 'success') {
                    uploadedFileUrl = fileUploadResponseParsed.data.file_url;
                }
            }
        }

        if (!isVideo && !isImage && xhr) {
            const { responseText } = xhr
            if (responseText) {
                const fileUploadResponseParsed = JSON.parse(responseText);
                if ((!previewUrl) && fileUploadResponseParsed.status === 'success') {
                    uploadedFileId = fileUploadResponseParsed.data.uid;
                }
            }
        }

        let title = `${name || ''}` //, ${formatBytes(size)}
        // if (duration) title = `${title}, ${formatDuration(duration)}`

        if (status === 'error_file_size' || status === 'error_validation') {

            return (
                <div className={className} style={style}>
                    <span className="dzu-previewFileNameError">{title}</span>
                    {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
                    {status === 'error_validation' && <span>{String(validationError)}</span>}
                    {canRemove && <span className="dzu-previewButton" onClick={this.onRemove} >x</span>}
                </div>
            )
        }

        if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
            title = `${title} (upload failed)`
        }
        if (status === 'aborted') title = `${title} (cancelled)`
        return (
            <div className={'drop-zone-img-preview'}>
                 {isImage && <>
                    {previewUrl && !this.props.isImageCropper && !this.props.imageUrl && this.props.isLogo !== undefined &&
                    <CropperModal getCroppedImageData={this.props.getCroppedImageData} fileUrl={this.props.imageUrl} previewUrl={previewUrl} onRemove={this.onRemove} isLogo={this.props.isLogo} />}
                    {previewUrl && this.props.imageUrl && this.props.isLogo !== undefined && <img className={''} style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%' }} src={this.props.imageUrl} alt={title} title={title} />}
                    {previewUrl && uploadedFileUrl && this.props.isLogo === undefined && status !== "error_upload" && <img className={''} style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%' }} src={previewUrl || uploadedFileUrl} alt={title} title={title} />}
                </>}
                {isVideo && <>
                    {(previewUrl || uploadedFileUrl) && <video width="320" height="240" controls>
                        <source src={previewUrl || uploadedFileUrl} />
                        Your browser does not support the video tag.
                    </video>}
                </>}
                {!isImage && !isVideo && <>
                    {(previewUrl || uploadedFileId) && <span className="dzu-previewFileName preview-docx">{title}</span>}
                </>}

                <div className="dzu-previewStatusContainer">
                    {isImage && <>
                   <div className="fixProgress"> {!uploadedFileUrl && <span className="dzu-previewFileName">{title}</span>}</div>
                    </>}
                    {isVideo && <> {(!previewUrl && !uploadedFileUrl) && <span className="dzu-previewFileName">{title}</span> }
                    </>}

                    {isVideo && isUpload && !previewUrl && percent !== 100 &&(
                        <progress max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent} />
                    )}

                    {isImage && isUpload && !uploadedFileUrl && percent !== 101 &&(
                        <progress max={100} value={(status === 'done' || status === 'headers_received') && uploadedFileUrl ? 100 : percent} />
                    )}
                    
                    {status === 'uploading' && canCancel && (<span className={'upload-icons'} style={{ position: 'absolute', top: 20, left: 20 }}><CancelIcon onClick={cancel} /></span>)}

                    {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (<span className={'upload-icons'} style={{ position: 'absolute', top: 20, left: 20 }}><DeleteIcon onClick={this.onRemove} /></span>)}

                    {!previewUrl && ['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) && canRestart && (<span className={'upload-icons'} style={{ position: 'absolute', top: 20, right: 20 }}><RefreshIcon onClick={restart} /></span>)}

                    {previewUrl && ['error_upload'].includes(status) && canRestart && (<span className={'upload-icons'} style={{ position: 'absolute', top: 20, right: 20 }}><RefreshIcon onClick={this.onRemove} /></span>)}
                </div>
            </div>
        )
    }
}

// @ts-ignore
Preview.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    style: PropTypes.object,
    imageStyle: PropTypes.object,
    fileWithMeta: PropTypes.shape({
        file: PropTypes.any.isRequired,
        meta: PropTypes.object.isRequired,
        cancel: PropTypes.func.isRequired,
        restart: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        xhr: PropTypes.any,
    }).isRequired,
    // copy of fileWithMeta.meta, won't be mutated
    meta: PropTypes.shape({
        status: PropTypes.oneOf([
            'preparing',
            'error_file_size',
            'error_validation',
            'ready',
            'getting_upload_params',
            'error_upload_params',
            'uploading',
            'exception_upload',
            'aborted',
            'error_upload',
            'headers_received',
            'done',
        ]).isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string,
        uploadedDate: PropTypes.string.isRequired,
        percent: PropTypes.number,
        size: PropTypes.number,
        lastModifiedDate: PropTypes.string,
        previewUrl: PropTypes.string,
        duration: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        videoWidth: PropTypes.number,
        videoHeight: PropTypes.number,
        validationError: PropTypes.any,
    }).isRequired,
    isUpload: PropTypes.bool.isRequired,
    canCancel: PropTypes.bool.isRequired,
    canRemove: PropTypes.bool.isRequired,
    canRestart: PropTypes.bool.isRequired,
    files: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line react/no-unused-prop-types
    extra: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        reject: PropTypes.bool.isRequired,
        dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
        accept: PropTypes.string.isRequired,
        multiple: PropTypes.bool.isRequired,
        minSizeBytes: PropTypes.number.isRequired,
        maxSizeBytes: PropTypes.number.isRequired,
        maxFiles: PropTypes.number.isRequired,
    }).isRequired,
}

export default Preview
