export const API_URLS = {
    TIMEZONE_LIST_URL: `timeZone`,
    CREATE_EVENT_URL: `event/createEvent`,
    UPDATE_EVENT_URL: 'event/E_ID/update',
    GET_EVENT_DETAILS: 'event/E_ID/get',
    COPY_EVENT_URL: 'event/copy',

    UPLOAD_FILE_URL: `upload/uploadFile`,
    DEFAULT_SKINS_URL: `skin`,
    GET_EMOJI_ICON: `emoji`,
    UPDATE_ROOM_URL: `event/E_ID/room/U_ID/update`,
    CHANGE_GROUP_CHAT_STATUS: 'event/E_ID/event/groupChat/changeStatus',
    CLEAR_GROUP_CHAT: 'event/E_ID/event/groupChat/clearGroupChat',
    DOWNLOAD_CHAT_DATA: 'event/E_ID/event/groupChat/downloadGroupChat',
    CHANGE_SOCIAL_SHARE_LINK_STATUS: 'event/E_ID/event/socialShare/changeStatus',
    CHANGE_SOCIAL_LOGIN_STATUS: 'event/E_ID/event/socialLogin/changeStatus',

    CREATE_EVENT_TAG_URL: `event/E_ID/tag/create`,
    GET_EVENT_TAGS_URL: `event/E_ID/tag/list`,
    DELETE_EVENT_TAG_URL: `event/E_ID/tag/U_ID/delete`,
    UPDATE_NETWORKING_CHANGE_URL: `event/E_ID/networking/element/U_ID/active`,

    GET_BOOTHS_LIST_URL: `event/E_ID/booth/list`,
    GET_ALL_BOOTH_LIST_URL: `event/E_ID/booth/list/all`,
    UPDATE_EVENT_BOOTH_ZONE_URL: `event/E_ID/booth/update/zone`,
    GET_ZONES_LIST_URL: `event/E_ID/zone/list`,
    CREATE_BOOTHS_LIST_URL: `event/E_ID/booth/create`,
    UPDATE_BOOTHS_LIST_URL: `event/E_ID/booth/U_ID/update`,
    UPDATE_BOOTH_URL: `event/E_ID/booth/U_ID/update`,
    ADD_BOOTH_OWNERS_URL: `event/E_ID/booth/U_ID/update/user`,
    CREATE_ZONE_URL: `event/E_ID/zone/create`,
    GET_ZONE_DETAILS_URL: `event/E_ID/zone/U_ID/get`,
    DELETE_ZONE_URL: `event/E_ID/zone/U_ID/delete`,
    DELETE_BOOTH_URL: `event/E_ID/booth/U_ID/delete`,
    UPDATE_ZONE_URL: `event/E_ID/zone/U_ID/update`,
    GET_EVENT_BOOTH_DETAIL_URL: `event/E_ID/booth/U_ID/get`,
    GET_EVENT_USERS_LIST_URL: `event/E_ID/user/list`,
    CHECK_IF_BOOTH_OWNER_EXISTS_URL: `event/E_ID/booth/U_ID/owner/check`,
    CREATE_BOOTH_OWNER_URL: `event/E_ID/booth/U_ID/owner/create`,
    UPDATE_BOOTH_OWNER_URL: `event/E_ID/booth/B_ID/owner/U_ID/update`,

    SPEAKERS_LIST_URL: `event/E_ID/speaker/list`,
    SPEAKER_DELETE_URL: `event/E_ID/speaker/U_ID/delete`,
    CHECK_IF_EMAIL_EXIST_URL: `event/E_ID/speaker/check`,
    UPLOAD_SPEAKER_URL: `event/E_ID/speaker/import`,
    CREATE_SPEAKER_URL: `event/E_ID/speaker/create`,
    UPDATE_SPEAKER_URL: `event/E_ID/speaker/U_ID/update`,
    GET_SPEAKER_DETAILS_URL: `event/E_ID/speaker/U_ID/get`,
    SPEAKER_EMAIL_INVITATION_URL: 'event/E_ID/speaker/U_ID/sendInvite',
    CHANGE_SPEAKER_ROLE_URL: 'event/E_ID/speaker/U_ID/changeRole',


    GET_LANDING_PAGE_URL: `event/E_ID/landing`,
    UPDATE_EVENT_LANDING_PAGE_DETAILS_URL: `event/E_ID/landing/update`,
    GET_EVENT_LANDING_PAGE_INFO_URL: `event/E_ID/landing/information`,
    UPDATE_EVENT_LANDING_PAGE_INFO_URL: `event/E_ID/landing/information/update`,
    GET_EVENT_LANDING_PAGE_CONTENT: `event/E_ID/landing/content`,
    UPDATE_EVENT_LANDING_PAGE_CONTENT: `event/E_ID/landing/content/update`,

    GET_EVENT_SCHEDULE_DAYS_URL: `event/E_ID/schedule/days`,
    CREATE_EVENT_SCHEDULE_URL: `event/E_ID/schedule/create`,
    UPDATE_EVENT_SCHEDULE_URL: `event/E_ID/schedule/U_ID/update`,
    GET_SCHEDULES_LIST_BY_DATE: `event/E_ID/schedule/date`,
    DELETE_EVENT_SCHEDULE_URL: 'event/E_ID/schedule/U_ID/delete',
    HIDE_EVENT_SCHEDULE_URL: 'event/E_ID/schedule/U_ID/hideSegment',

    GET_ZONE_SECTIONS_STATUS: `event/E_ID/event/zones`,

    GET_STAGES_LIST_URL: 'event/E_ID/stage/list',
    CREATE_STAGE_URL: 'event/E_ID/stage/create',
    GET_STAGE_DATA_URL: 'event/E_ID/stage/U_ID/get',
    UPDATE_STAGE_URL: 'event/E_ID/stage/U_ID/update',
    DELETE_STAGE_URL: 'event/E_ID/stage/U_ID/delete',

    GET_LOBBY_URL: 'event/E_ID/lobby',
    UPDATE_LOBBY_URL: 'event/E_ID/lobby/update',

    ROOM_REORDER_URL: 'event/E_ID/room/order',
    GET_ROOMS_LIST: 'event/E_ID/room/list?type=U_ID',
    CREATE_ROOM: 'event/E_ID/room/create',
    DELETE_ROOM: 'event/E_ID/room/U_ID/delete',
    GET_ROOM_DETAILS: 'event/E_ID/room/U_ID/get',

    GET_NETWORKING_DETAILS: 'event/E_ID/networking',
    UPDATE_NETWORKING: 'event/E_ID/networking/update',
    GET_NETWORKING_ELEMENTS: 'event/E_ID/networking/element/list',
    CREATE_NETWORKING_ELEMENT: 'event/E_ID/networking/element/create',
    DELETE_NETWORKING_ELEMENT: 'event/E_ID/networking/element/U_ID/delete',
    EDIT_NETWORKING_ELEMENT: 'event/E_ID/networking/element/U_ID/update',

    GET_ZONES_STATUS: 'event/E_ID/event/zones',
    GET_ZONES_INTERNAL_SECTIONS_LIST: 'event/E_ID/event/zones/internal',
    UPDATE_ZONE_STATUS: 'event/E_ID/event/zones/update',
    UPDATE_COMPONENT_HEADER_NAME_URL: 'event/E_ID/event/zones/updateName',

    GET_WIDGETS: 'event/E_ID/widget/U_ID/list', // E_ID : event id, U_ID: layout id
    CREATE_WIDGET: 'event/E_ID/widget/U_ID/create', // E_ID : event id, U_ID: layout id
    UPDATE_WIDGET: 'event/E_ID/widget/U_ID/update', // E_ID : event id, U_ID: layout id
    DELETE_WIDGET: 'event/E_ID/widget/U_ID/delete', // E_ID : event id, U_ID: layout id

    GET_ATTENDEES_LIST_URL: 'event/E_ID/attendee/list',
    UPLOAD_ATTENDEES_LIST_URL: 'event/E_ID/attendee/import',
    DELETE_ATTENDEE_URL: 'event/E_ID/attendee/U_ID/delete',
    ADD_ATTENDEES_BY_EMAIL: 'event/E_ID/attendee/import/emails',
    ATTENDEES_EMAIL_INVITATION_URL: 'event/E_ID/attendee/U_ID/sendInvite',


    CHECK_IF_TEAM_EMAIL_EXIST_URL: `team/check`,
    CREATE_TEAM_URL: `team/create`,
    UPDATE_TEAM_URL: `team/U_ID/update`,
    LIST_TEAM_URL: `team/list`,
    DELETE_TEAM_URL: `team/E_ID/U_ID/delete`,

    GET_ORGANIZATIONS: 'team/organizations',

    GET_TOTAL_SIGNUP_REPORT: 'event/E_ID/reports/totalSignUp',
    GET_TOTAL_SIGNUP_REPORT_EXPORT: 'event/E_ID/reports/totalSignUp/export',
    GET_TOTAL_LOGGED_IN: 'event/E_ID/reports/totalLoggedIn',
    GET_TOTAL_LOGGED_IN_EXPORT: 'event/E_ID/reports/totalLoggedIn/export',
    GET_TOTAL_STREAMING_HOURS_REPORT: 'event/E_ID/reports/totalStreamingHours',
    GET_TOTAL_STREAMING_HOURS_REPORT_EXPORT: 'event/E_ID/reports/totalStreamingHours/export',
    GET_TOTAL_LIVE_LOCATION_REPORT: 'event/E_ID/reports/totalLiveLocation',
    GET_TOTAL_LIVE_LOCATION_REPORT_EXPORT: 'event/E_ID/reports/totalLiveLocation/export',
    GET_TOTAL_ROOM_USER_REPORT: 'event/E_ID/reports/roomUser',
    EXPORT_ROOMS:'event/E_ID/reports/roomUser/export',
    GET_TOTAL_ROOM_USER_EXPORT: 'event/E_ID/reports/roomUser/export',
    GET_ALL_DASHBOARD_DATA_URL: 'event/E_ID/reports/dashboardData',
    GET_USAGES_RATES_DATA_URL: 'event/E_ID/reports/usageRates',
    GET_LOBBY_DATA_REPORTS_URL: 'event/E_ID/reports/lobbyData',
    EXPORT_LOBBY:'event/E_ID/reports/lobbyData/export',
    GET_STAGES_DATA_REPORTS_URL: 'event/E_ID/reports/stagesData',
    GET_STAGES_SESSION_DATA_URL: 'event/E_ID/reports/stagesSessionData/U_ID',
    GET_EACH_SESSION_DATA_URL: 'event/E_ID/reports/sessionData/export/U_ID',
    GET_EXPO_DATA_URL: 'event/E_ID/reports/expoList',
    GET_EXPO_DATA_LIST_URL: 'event/E_ID/reports/expoData/U_ID',
    BOOTH_EXPORT:'event/E_ID/reports/expoData/export/U_ID',
    GET_FEEDBACK_DATA_URL: 'event/E_ID/reports/feedbackData',
    GET_REGISTRATION_GRAPH_DATA_URL: 'event/E_ID/reports/totalRegistration',
    GET_LOGIN_GRAPH_DATA_URL: 'event/E_ID/reports/totalLogin',
    EXPORT_FEEDBACK:'event/E_ID/reports/feedbackData/export',
    GET_EXPO_REPORT_SUMMARY_URL: 'event/E_ID/reports/expoReportSummary',
    GET_EXPO_REPORT_SUMMARY_EXPORT_URL: 'event/E_ID/reports/expoReportSummaryExport',
    GET_ALL_EXHIBITORS_REPORT_URL: 'event/E_ID/reports/allExpoReportExport',
    GET_SPECIFIC_EXHIBITORS_REPORT_URL: 'event/E_ID/reports/expoReportExport/U_ID',
    GET_MEETING_SCHEDULE_REPORT_SUMMARY_URL: 'event/E_ID/reports/meetingSchedularData',
    GET_MEETING_SCHEDULER_LIST_REPORT: 'event/E_ID/reports/meetingSchedular',
    GET_MEETING_SCHEDULER_REPORT_EXPORT: 'event/E_ID/reports/meetingSchedular/export',


    GET_NOTIFICATION_LIST: 'event/E_ID/notification/list',
    GET_NOTIFICATION_TARGET_GROUP: 'event/E_ID/groups',
    CREATE_NOTIFICATION: 'event/E_ID/notification/create',
    UPDATE_NOTIFICATION: 'event/E_ID/notification/U_ID/update',
    GET_NOTIFICATION_BY_ID: 'event/E_ID/notification/U_ID/get',
    DELETE_NOTIFICATION: 'event/E_ID/notification/U_ID/delete',
    PUSH_NOTIFICATION: 'event/E_ID/notification/U_ID/push',
    CLEAR_USER_NOTIFICATION: 'event/E_ID/notification/clearUsersNotifications',
    GET_SCHEDULE_LIST_URL: 'event/E_ID/stage/U_ID/getschedule',

    GET_RECORDING_LIST: 'event/E_ID/recording/list',
    GET_RECORDING_VIDEO_URL: 'event/E_ID/recording/U_ID/url',

    GET_HELP_DESK_USER_LIST: 'event/E_ID/helpDesk/list',
    GET_HELP_DESK_USER_CHECK: 'event/E_ID/helpDesk/check',
    GET_HELP_DESK_USER_CREATE: 'event/E_ID/helpDesk/create',
    GET_HELP_DESK_USER_SENT_EMAIL_INVITE: 'event/E_ID/helpDesk/U_ID/sendInvite',
    GET_HELP_DESK_USER_UPDATE: 'event/E_ID/helpDesk/U_ID/update',
    GET_HELP_DESK_USER_DETAILS: 'event/E_ID/helpDesk/U_ID/get',
    GET_HELP_DESK_USER_DELETE: 'event/E_ID/helpDesk/U_ID/delete',

    GET_FEEDBACK_LIST: 'event/E_ID/feedback/list',
    DELETE_FEEDBACK: 'event/E_ID/feedback/U_ID/delete',
    GET_FEEDBACK_BY_ID: 'event/E_ID/feedback/U_ID/get',
    CREATE_FEEDBACK: 'event/E_ID/feedback/create',
    UPDATE_FEEDBACK: 'event/E_ID/feedback/U_ID/update',
    UPDATE_FEEDBACK_STATUS: 'event/E_ID/feedback/U_ID/changeStatus',


    GET_QUESTION_LIST: 'event/E_ID/feedback/F_ID/question/list',
    DELETE_QUESTION: 'event/E_ID/feedback/F_ID/question/U_ID/delete',
    GET_QUESTION_BY_ID: 'event/E_ID/feedback/F_ID/question/U_ID/get',
    CREATE_QUESTION: 'event/E_ID/feedback/F_ID/question/create',
    UPDATE_QUESTION: 'event/E_ID/feedback/F_ID/question/U_ID/update',
    UPDATE_QUESTION_STATUS:'event/E_ID/feedback/F_ID/question/U_ID/changeStatus',
    CHANGE_QUESTION_ORDER: 'event/E_ID/feedback/F_ID/question/order',


    GET_EMAILER_LIST: 'event/E_ID/emailTemplate/list',
    CREATE_EMAILER: 'event/E_ID/emailTemplate/create',
    UPDATE_EMAILER: 'event/E_ID/emailTemplate/U_ID/update',
    GET_EMAILER_BY_ID: 'event/E_ID/emailTemplate/U_ID/get',
    DELETE_EMAILER: 'event/E_ID/emailTemplate/U_ID/delete',
    PUSH_EMAIL_TEMPLATE: 'event/E_ID/emailTemplate/U_ID/push',
    DYNAMIC_KEYWORDS: 'event/E_ID/emailTemplate/keywords',
    SEND_EMAIL:'event/E_ID/emailTemplate/U_ID/sendtestmail',

    GET_SUBSCRIPTION_PLAN: 'subscription/getplans',
    POST_HOSTED_ID: 'subscription/hostedPage',
    GET_ACTIVE_PLAN: 'subscription/getUserCurrentPlan',
    CANCEL_SUBSCRIPTION:'subscription/cancelsubscription',
    PURCHASE_HISTORY:'subscription/getUserPurchaseHistory',
    DOWNLOAD_INVOICE:'subscription/downloadEInvoice',

    GET_SECRETS_URL: 'event/E_ID/getsecrets',
    GENERATE_SECRET_KEY: 'event/E_ID/generatekey',
    GET_SOURCE_LIST_URL: 'event/E_ID/source/list',
    VIEW_SOURCE_DETAILS_URL: 'event/E_ID/source/U_ID/get',
    CREATE_SOURCE_URL: 'event/E_ID/source/create',
    DELETE_SOURCE_URL: 'event/E_ID/source/U_ID/delete',
    CHANGE_SOURCE_STATUS_URL: 'event/E_ID/source/U_ID/changeStatus',

    SET_EVENT_LANGUAGE_URL: 'event/E_ID/updateLanguage',

    GET_EMBED_WIDGET_URL: 'event/E_ID/embeddedRegistration/getEmbedWidget',
    UPDATE_EMBED_WIDGET_URL: 'event/E_ID/embeddedRegistration/updateEmbedWidget',

    TOGGLE_MEETING_SCHEDULER_URL: 'event/E_ID/meeting/toggle',
    GET_MEETING_SCHEDULER_DETAILS: 'event/E_ID/meeting',
    UPDATE_MEETING_SCHEDULER_URL: 'event/E_ID/meeting/update',

    MASTER_OTP_UPDATE_URL: 'event/E_ID/updateMasterOtp',

    // UPDATE_EMAILER: 'event/E_ID/emailTemplate/U_ID/update',
    // GET_EMAILER_BY_ID: 'event/E_ID/emailTemplate/U_ID/get',
    // DELETE_EMAILER: 'event/E_ID/emailTemplate/U_ID/delete',
    // PUSH_EMAIL_TEMPLATE: 'event/E_ID/emailTemplate/U_ID/push',
    // DYNAMIC_KEYWORDS: 'event/E_ID/emailTemplate/keywords',


}
