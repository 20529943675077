import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { AsideMenuList } from "./AsideMenuList";
import { SecondaryAsideMenuList } from "./SecondaryAsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { PAGES_WITH_SECONDARY_MENU } from "../../../../../app/constants";
export function AsideMenu({ disableScroll, sideMenuVariant, activeMenu }) {
  const location = useLocation();
  const pathname = location.pathname;
  const state = location.state || {}
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {/* {(PAGES_WITH_SECONDARY_MENU.indexOf(pathname) < 0 || state.sideBarVariant === 'primary') && <AsideMenuList layoutProps={layoutProps} />}
        {(PAGES_WITH_SECONDARY_MENU.indexOf(pathname) > -1 || state.sideBarVariant === 'secondary') && <SecondaryAsideMenuList layoutProps={layoutProps} />} */}
         
        {(sideMenuVariant === 'primary' || !sideMenuVariant) && <AsideMenuList layoutProps={layoutProps} />}
        {(sideMenuVariant === 'secondary') && <SecondaryAsideMenuList layoutProps={layoutProps} />}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
