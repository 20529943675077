/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useEffect } from "react";
import { Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import { useRouter } from "../component/CustomHook/useRouter";
import ReactGA from 'react-ga';

const { initialize, pageview, ga } = ReactGA;

initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);

const Routes = () => {

  const router = useRouter();

  const { isAuthorized, showRegGeneralIntentPage, showRegYourOrgInfoPage, showRegEventInfo } = useSelector(({ auth }) => {
    return {
      isAuthorized: auth.authToken != null && auth.authToken != undefined,
      showRegGeneralIntentPage: auth.askGeneralIntent,
      showRegYourOrgInfoPage: auth.askGeneralIntent,
      showRegEventInfo: auth.askGeneralIntent
    }
  }, shallowEqual);

  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
    ga("send", "pageview", window.location.pathname);
  }, [router.pathname]);

  useEffect(() => {
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/cf3d4266-7bf3-4b32-b129-26a6b00bfc5e.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }, []);

  return (
    <Switch>
      {(!isAuthorized || showRegGeneralIntentPage || showRegYourOrgInfoPage || showRegEventInfo) ? <AuthPage /> : <BasePage />}
    </Switch>
  );
}
export { Routes };
