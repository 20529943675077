import { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { VscClose } from "react-icons/vsc";
import { showToast } from '../../utils';
import ImageCropper from './ImageCropper';

export const CropperModal = (props) => {
    const { fileUrl, previewUrl, getCroppedImageData, onRemove, isLogo } = props;
    const [croppedImage, setCroppedImage] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(previewUrl && !showModal) {
            setShowModal(true);
        }
    },[previewUrl])

   const getCropImageUrl = () => {
      if(croppedImage) {
          let imageUrl = window.URL.createObjectURL(croppedImage);
          getCroppedImageData(croppedImage, imageUrl);
          setShowModal(false);
      } else {
          showToast({ variant: 'error', message: "Please adjust image." });
      }
    }

    const onHide = () => {
        onRemove();
        setShowModal(false);
    }


    return <>
        <Modal className="ModalCommonInfo CripViewImages" show={showModal}>
            <Modal.Body>
                <ImageCropper
                  isLogo={isLogo}
                  imageToCrop={previewUrl}
                  onImageCropped={(croppedImage) => {setCroppedImage(croppedImage)}}
                  />
              <img src={fileUrl} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='CropImg' onClick={getCropImageUrl}>Crop</Button>
                <Button className='ChangeImg' onClick={onHide}>Change</Button>            
            </Modal.Footer>
            
            <div className="ModalClose">
            </div>
        </Modal>
    </>
}

export default CropperModal;
