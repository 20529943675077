import { useEffect, useState, Fragment } from "react";

const DialCode = ({
    list,
    onChange,
    val,
    recommended,
}) => {

    const [arrow, setArrow] = useState(false);
    const [selectedVal, setSelectedVal] = useState("");
    const [optionList, setOptionList] = useState([]);

    useEffect(() => {
        let selectedDialVal = list && list.filter(({ dial_code }) => dial_code === val);
        if (selectedDialVal && selectedDialVal.length) {
            setSelectedVal(selectedDialVal[0].dial_code + ' (' + selectedDialVal[0].name + ')');
        }
        setOptionList(list);
    }, [list]);

    return (
        <Fragment>
            <div className="TimeZoneProfile">
                <div className={`ZoneSelectBox ${arrow ? 'arrow-icon' : ''}`}>
                    <input type="text" className="valueInput" value={selectedVal} readOnly onClick={() => { setArrow(!arrow) }} />
                </div>
                {arrow ? <div className="TimeZoneActive">
                    <div className="ZoneSearchHere">
                        <input type="text" className="SearchBarInput" placeholder="Search code" onChange={(e) => {
                            const filteredData = list.filter(item => {
                                return Object.keys(item).some(key => {
                                    if (item[key] === null || typeof item[key] === 'object') {
                                        return;
                                    }

                                    return (item.dial_code + ' (' + item.name + ')').toLowerCase().includes(e.target.value.toLowerCase()) || (item.dial_code + ' ' + item.name).toLowerCase().includes(e.target.value.toLowerCase());
                                });
                            });
                            setOptionList(filteredData);
                        }} />
                    </div>
                    {recommended && recommended.length ?
                        <div className="RecommendedValue">
                            <b>Recommended</b>
                            <ul>
                                {
                                    recommended.map((e) => {
                                        return (
                                            <li key={e}
                                                onClick={() => {
                                                    setSelectedVal(e.dial_code + ' (' + e.name + ')');
                                                    setArrow(false);
                                                    let obj = { target: { name: "dial_code", value: e.dial_code } };
                                                    onChange(obj);
                                                }}>{e.dial_code + ' (' + e.name + ')'}</li>
                                        )
                                    })}
                            </ul>
                        </div> : null}
                    <div className="ZoneOptionBox">
                        <ul>
                            {optionList && optionList.length ?
                                optionList.map((e, index) => {
                                    return (
                                        <li key={e + index + "dial_code"}
                                            onClick={() => {
                                                setSelectedVal(e.dial_code + ' (' + e.name + ')');
                                                setOptionList(list);
                                                setArrow(false);
                                                let obj = { target: { name: "dial_code", value: e.dial_code } };
                                                onChange(obj);
                                            }}>{e.dial_code + ' (' + e.name + ')'}</li>
                                    )
                                })
                                : null}
                        </ul>
                    </div>
                </div> : null}
            </div>
        </Fragment>
    )
}
export default DialCode