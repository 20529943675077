import React, { useMemo } from "react";
import { Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";

function BrandBase(props) {
  // console.log('********************* BRAND PROPS *******************', props)
  const uiService = useHtmlClassService();
  const { location: { pathname: urlPath }} = props;
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand LogoMixhibbLeft flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        {urlPath !== '/dashboard' && <Button disableRipple variant="text" onClick={() => { props.history.goBack(); }} className="MixhubbLeftIco">
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer" enableBackground="new 0 0 64 64" height="48" viewBox="0 0 64 64" width="48"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" fill="#000000"></path></svg>
        </Button>}
        <Link to="/dashboard" className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo} />
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}

export const Brand = withRouter(BrandBase);