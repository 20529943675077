import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../../../app/modules/Auth/_redux/authRedux";

const Logout = ({ history }) => {
  const dispatch = useDispatch();
  const { logout } = actions;

  useEffect(() => {
    dispatch(logout());
    history.replace("/registration");
  }, []);

  return (null)
}
export default Logout;
