import { Modal } from "react-bootstrap";
import { Button } from '@material-ui/core';
import { BsX } from "react-icons/bs";

const ModalWrapper = ({
    title,
    showModal,
    onHide,
    children,
    footerContent,
    cssCls
}) => {
    return (
        <Modal className={`CommonModalPopup  ${cssCls}`} show={showModal} onHide={onHide}>
            <Modal.Header closeButton>
                {onHide ? <Button className="ModalCloseBtn" onClick={onHide}><BsX /></Button> : null}
                {title ? <Modal.Title>
                    <h4>{title}</h4>
                </Modal.Title> : null}
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {footerContent ?
                <Modal.Footer>
                    {footerContent}
                </Modal.Footer>
                : null
            }
        </Modal>
    )
}
export default ModalWrapper;