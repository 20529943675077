import React from "react";
import { svgComponents } from "../../app/modules/Events/components/addEvent/svgComponents";

const DashboardAccess =()=> {
    return (
        <div className="MobileViewInfo">
            <div className="MobDeshboard">
                <span>{svgComponents.MobDeshboard}</span>
                <p>Organiser dashboard can only be accessed from a desktop.</p>
                <p>In case of any assistance please write us at <a href="mailto: hello@mixhubb.com" target="_blank"> hello@mixhubb.com</a></p>
            </div>
        </div>
    )
}
export default DashboardAccess;
