// import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { reducer } from "../app/modules/Events/_redux/reducer";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  events: reducer
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
