import { InputLabel, TextField, MenuItem } from '@material-ui/core';
import "./style.css";
import ToolTip from '../ToolTip/ToolTip';
import DialCode from '../DialCode/DialCode';
import { useEffect, useState } from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { svgComponents } from '../../app/modules/Events/components/addEvent/svgComponents';

const InputForm = ({
    colCls,
    disabled,
    inputCls,
    label,
    placeholder,
    type,
    name,
    value,
    onChange,
    multiline = false,
    row,
    rowsMin,
    inputProps,
    isErr,
    errName,
    id = '',
    child,
    isToolTip = false,
    toolTipTitle = "",
    toolTipTitlePlacement = "",
    toolTipCls = "",
    select = false,
    options,
    required = false,
    fromOtherComponent = false,
    labelCls = "",
    defaultValue = "",
    changeType,
    showEyeIcon = false,
}) => {

    const [dialList, setDialList] = useState([]);

    useEffect(() => {
        if (name === "dial_code") {
            if (options && options.length) {
                let tempArr = [];
                options.map(({ label }) => {
                    tempArr.push(label);
                });
                setDialList(tempArr);
            }
        }
    }, [options])

    return (
        <div className={`${colCls} pr-2`}>
            <div className="form-group GroupForm 1">
                <div className={multiline ? "fv-plugins-icon-container eventInserted EventDescript" : labelCls}>
                    <InputLabel>
                        {label}{required ? "* " : null}
                        {isToolTip ?
                            <ToolTip
                                title={toolTipTitle}
                                toolTipCls={toolTipCls}
                                arrow placement={toolTipTitlePlacement}>
                                <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                            </ToolTip>
                            : null}
                    </InputLabel>
                    {name === "dial_code" ?
                        <DialCode
                            list={dialList}
                            onChange={onChange}
                            val={value}
                            recommended={[]}
                        />
                        :
                        <>
                            <TextField
                                select={select}
                                id={id}
                                disabled={disabled}
                                className={`form-control form-control-solid 1 h-auto py-5 pb-1 pt-7 px-5 ${inputCls}`}
                                placeholder={placeholder}
                                type={type}
                                name={name}
                                value={value}
                                onChange={onChange}
                                multiline={multiline}
                                rows={row}
                                rowsmin={rowsMin}
                                inputProps={inputProps}
                                required={required}
                                defaultValue={defaultValue}
                            >
                                {select && options && options.length ?
                                    options.map((option, index) => {
                                        return (
                                            <MenuItem key={option.value + index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    })
                                    : null}
                            </TextField>
                            {
                                showEyeIcon ? <button className="btn-eye" onClick={() => {
                                    if (type === 'password') {
                                        if (changeType) changeType(name, 'text')
                                    } else {
                                        if (changeType) changeType(name, 'password')
                                    }
                                }} type='button'>{type === 'text' ? svgComponents.eyesOff : svgComponents.eyesOn}</button> : null
                            }

                        </>
                    }
                    {!fromOtherComponent && isErr && errName && !value ? <div className="text-danger">{errName}</div> : null}
                    {fromOtherComponent && errName && value ? <div className="text-danger">{errName}</div> : null}
                    {fromOtherComponent && errName && !value ? <div className="text-danger">{errName}</div> : null}

                    {inputProps && inputProps.maxLength ? value ? <div className="LimitDivTxg">{`${value.length} / ${inputProps.maxLength}`}</div> : null : null}
                    {child ? child : null}
                </div>
            </div>
        </div>

    )
}
export default InputForm;