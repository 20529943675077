import { Tooltip } from '@material-ui/core';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToolTip = ({
    title,
    placement,
    toolTipCls
}) => {
    return (
        <Tooltip title={title} arrow placement={placement} className={toolTipCls}>
            <span><FontAwesomeIcon icon={faInfoCircle} /></span>
        </Tooltip>
    )
}
export default ToolTip;