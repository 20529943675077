export const actionTypes = {
    AddEventData: "Save data of event being added",
    RemoveEventData:"Remove data of event being added from state",
    UpdateEventData: "Update data of event being added from state",
    SaveCurrentEventData: "Current event data which is being edited/ whose details are being viewed",
    SetScheduleFormVisibility: "Show/hide appointment add edit form",
    SetScheduleFormData: "Set form data in state when adding/editing a schedule",
    SetEventSpeakers: "Set Event speakers",
    SetEventTags: "Set Event tags",
    SetZonesWithStatus: "SetEventZones with status",
    GoToEmailTemplateList:"GoToEmailTemplateList",
    GoToNotificationList:"GoToNotificationList"
};
