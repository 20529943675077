import { Fragment, useState } from "react";
import { Button } from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../Auth/_redux/authCrud";
import { showToast } from "../../utils";
import InputForm from "../../../component/InputForm/InputForm";
import ModalWrapper from "../../../component/ModalWrapper/ModalWrapper";
import { svgComponents } from '../Events/components/addEvent/svgComponents';

export function ChangePassword(props) {
	const { hide } = props;
	const [initialValues] = useState({ old_password: "", password: "", confirm_password: "" });
	const [validateOnChange, setValidateOnChange] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [passType, setPassType] = useState({ old_password: 'password', password: 'password', confirm_password: 'password' })

	const ChangePasswordSchema = Yup.object().shape({
		old_password: Yup.string()
			.required("Old password is required"),
		password: Yup.string()
			.min(8, "Password must be minimum 8 characters long")
			.max(50, "Password must be maximum 50 characters long")
			.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-]).{8,}$/gm, "Password should contain alphanumeric values, at least one uppercase letter, one lowercase letter, and one special character")
			.required("Enter new password"),
		confirm_password: Yup.string()
			.required("Confirm new password")
	});

	const passwordUpdate = () => {
		setValidateOnChange(true);
		formik.handleSubmit();
	}

	const changeType = (name, value) =>{
		console.log({name,value})
		setPassType({
			...passType,
			[name]:value
		})
	}

	const formik = useFormik({
		initialValues,
		validationSchema: ChangePasswordSchema,
		validateOnChange: validateOnChange,
		onSubmit: (values) => {
			if (values.password === values.confirm_password) {
				const valuesToSubmit = {
					old_password: values.old_password,
					password: values.password
				}
				changePassword(valuesToSubmit).then((res) => {
					if (res.data.status === 'success') {
						showToast({ message: res.data.message, variant: 'success' });
						hide();
					}
				}).catch((error) => {
					showToast({ message: error.response?.data?.message || "Some error occurred", variant: 'error' })
				})
			}
		},
	});


	let changePasswordArr = [
		{
			colCls: "col-md-12", label: "Old Password", inputCls: "px-6", placeholder: "", type: passType.old_password, name: "old_password",
			value: formik.values.old_password ? formik.values.old_password : "", onChange: formik.handleChange, row: "", rowsMin: "",
			inputProps: {}, errName: formik.errors.old_password, required: true, showEyeIcon: true,
		},
		{
			colCls: "col-md-12", label: "New Password", inputCls: "px-6", placeholder: "", type: passType.password, name: "password",
			value: formik.values.password ? formik.values.password : "", onChange: formik.handleChange, row: "", rowsMin: "",
			inputProps: {}, errName: formik.errors.password, required: true, isToolTip: true, toolTipTitle: "Password should contain alphanumeric values, at least one uppercase letter, one lowercase letter, and one special character.",
			toolTipTitlePlacement: "top", toolTipCls: "TooltopHover", showEyeIcon: true,
		},
		{
			colCls: "col-md-12", label: "Confirm Password", inputCls: "px-6", placeholder: "", type: passType.confirm_password, name: "confirm_password",
			value: formik.values.confirm_password ? formik.values.confirm_password : "", onChange: formik.handleChange,
			row: "", rowsMin: "", inputProps: {}, errName: formik.errors.confirm_password, required: true, showEyeIcon: true,
			child: formik.values.confirm_password && <div className="text-danger">{formik.values?.password === formik.values?.confirm_password ? null : "Password not matching"}</div>
		},

	];

	let footerContent = <Button type="submit" className="btn btn-primary ContBtn font-weight-bold px-9 py-4 my-3" onClick={passwordUpdate}>Update Password</Button>

	const handleClick = () => {
		setShowPassword(!showPassword)
	}

	return (
		<Fragment>
			<ModalWrapper
				title={"Change Password"}
				showModal={true}
				onHide={hide}
				footerContent={footerContent}
			>
				<form>
					{changePasswordArr.map((elem, index) => {
						return (
							<div className="password-wrapper" key={index}>
								<InputForm
									key={index.toString()}
									isErr={formik.errors}
									fromOtherComponent={true}
									{...elem}
									changeType={changeType}
								/>
							</div>
						)
					})}
				</form>
			</ModalWrapper>
		</Fragment>
	)

}
export default ChangePassword;