import { actionTypes } from './actionTypes';

export const actions = {
    addEventData: (eventData) => ({ type: actionTypes.AddEventData, payload: eventData }),

    removeEventData: () => ({type: actionTypes.RemoveEventData}),

    updateEventData: (eventData) => ({type: actionTypes.UpdateEventData, payload: eventData}),

    saveCurrentEventData: (eventData) => ({type:actionTypes.SaveCurrentEventData, payload: eventData}),

    //schedule actions
    setScheduleFormVisibility: (val) => ({type: actionTypes.SetScheduleFormVisibility, payload: val}),

    // this will add data for schedule form while adding or editing
    setScheduleFormData: (data) => ({type: actionTypes.SetScheduleFormData, payload: data}),

    setEventSpeakers: (data) => ({type: actionTypes.SetEventSpeakers, payload: data}),
    
    setEventTags: (data) => ({type: actionTypes.SetEventTags, payload: data}),

    setZonesWithStatus: (data) => ({type: actionTypes.SetZonesWithStatus, payload: data})


}