import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getCountryCode, getUserByToken } from "./authCrud";
import { actionTypes } from "../../Auth/_redux/authRedux";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {

          //   const { data: user } = await getUserByToken();
          // dispatch(props.fulfillUser({ name: 'jatin', email: 'jatin.s@taggbox.com' }));
          // dispatch(props.fulfillUser(null));
          // }
          // const getUserDetails = () => {
          getUserByToken().then((res) => {
            if (res.data.status === "success") {
              dispatch({ type: actionTypes.TimeZoneNActivePlan, payload: { timeZone: res?.data?.userTimezone, activePlan: res?.data?.authUserPlans } })
              dispatch(props.saveUserData(res.data.data));
            }
          }).catch((error) => {
            console.log(error);
          })

          getCountryCode().then((res) => {
            if (res && res.data && res.data.status === "success") {
                let tempArr = [];
                Object.keys(res.data.data).map((e, i, a) => {
                  tempArr.push({ code: Object.values(res.data.data)[i] })
                });
              dispatch({ type: actionTypes.CountryCode, payload: tempArr })
            }
          }).catch((err) => {

          })
        }


      } catch (error) {
        // console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      // dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
