import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Button } from '@material-ui/core';
import HelpIcon from '../../../_assets/images/HelpIcon.png';
export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
  	<div className="row m-0">
    	<div className="col-md-11 m-auto">
    		
    	</div>
    </div>
  );
}
