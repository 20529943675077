import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  //UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  VerifyTempToken: "Verify temp token action",
  PreserveLinkedInToken: "Preserve linkedin token from uri",
  SaveUserAuthToken: "Save user authentication token",
  AskGeneralIntent: "Ask general intent",
  PreserveTwitterTokens: "Twitter tokens",
  SaveUserIntents: "Save user intents",
  SaveOrganization: "Save Organization",
  SkipToDashboard: "Skip to dashboard and reset stated which are required for registration steps",
  SaveUserData: 'Save user data',
  IS_PROFILE: "IS_PROFILE",
  SaveOrganizationDetails: "SaveOrganizationDetails",
  TimeZoneNActivePlan: "TimeZoneNActivePlan",
  ActiveSubscriptionPlan: "ActiveSubscriptionPlan",
  CountryCode: "CountryCode"
};

const initialAuthState = {
  user: undefined,// { name: 'jk', firstname: 'jk' }, //null,//
  authToken: undefined, // "dakldasdad-dasdasd",
  linkedInToken: null,
  // askGeneralIntent: true
  isProfile: false,
  timeZoneNActivePlan: {},
  activeSubscriptionPlan: {},
  countryCode:[]
};

const reducer = persistReducer(
  { storage, key: "mixhub-auth-token", whitelist: ["authToken", "tokenType", "user"] },
  (state = initialAuthState, action) => {
    const { type, payload } = action;
    switch (type) {
      case actionTypes.Login: {
        const { authToken } = payload;
        return { authToken, user: undefined };
      }
      case actionTypes.Register: {
        const { email } = payload;
        return { email, user: undefined, registartionStep: 1, linkedInToken: null };
      }
      case actionTypes.VerifyTempToken: {
        const { email, emailVerified, nextToken } = payload;
        return { ...state, email, emailVerified, nextToken, linkedInToken: null }
      }
      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return { ...initialAuthState };
      }
      case actionTypes.UserLoaded: {
        const { user } = payload;
        // return { ...state, user };
        return { ...state };
      }
      case actionTypes.SetUser: {
        // const { user } = payload;
        return { ...state };
      }
      case actionTypes.PreserveLinkedInToken: {
        return { ...state, linkedInToken: payload.token }
      }
      case actionTypes.PreserveTwitterTokens: {
        return { ...state, twitterTokens: payload.tokens }
      }
      case actionTypes.SaveUserAuthToken: {
        return { ...state, linkedInToken: null, authToken: payload.token, tokenType: payload.token_type, user: { name: payload.name, email: payload.email, general_profile_complete: payload.general_profile_complete } }
      }
      case actionTypes.AskGeneralIntent: {
        return { ...state, askGeneralIntent: true, linkedInToken: null }
      }
      case actionTypes.SaveUserIntents: {
        return { ...state, userIntents: { intent: payload.userIntent, interests: payload.userInterests } }
      }
      case actionTypes.SaveOrganization: {
        const { name } = payload;
        return { ...state, organizationName: name };
      }
      case actionTypes.SkipToDashboard: {
        return { ...state, askGeneralIntent: false }
      }
      case actionTypes.SaveUserData: {
        const { user } = payload;
        return { ...state, user }
      }
      case actionTypes.IS_PROFILE: {
        return { ...state, isProfile: payload }
      }
      case actionTypes.SaveOrganizationDetails: {
        return { ...state, organizationDetails: payload }
      }
      case actionTypes.TimeZoneNActivePlan: {
        return { ...state, timeZoneNActivePlan: action.payload }
      }

      case actionTypes.ActiveSubscriptionPlan: {
        return { ...state, activeSubscriptionPlan: action.payload }
      }

      case actionTypes.CountryCode: {
        return { ...state, countryCode: action.payload }
      }
      default:
        return state;
    }
  }
);

const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  registration: (email) => ({ type: actionTypes.Register, payload: { email }, }),
  verifyTempToken: (data) => ({
    type: actionTypes.VerifyTempToken,
    payload: data //{emailVerified, nextToken, email}
  }),
  logout: () => {
    localStorage.clear()
    return { type: actionTypes.Logout }
  },
  requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user }, }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  preserverLinkedInToken: (token) => ({ type: actionTypes.PreserveLinkedInToken, payload: { token } }),
  preserveTwitterTokens: (tokens) => ({ type: actionTypes.PreserveTwitterTokens, payload: tokens }),
  saveUserAuthToken: ({ token, token_type, name, email, general_profile_complete }) => ({ type: actionTypes.SaveUserAuthToken, payload: { token, token_type, name, email, general_profile_complete } }),
  askGeneralIntent: () => ({ type: actionTypes.AskGeneralIntent }),
  saveUserIntents: ({ userIntent, userInterests }) => ({ type: actionTypes.SaveUserIntents, payload: { userInterests, userIntent } }),
  saveOrganization: (name) => ({ type: actionTypes.SaveOrganization, payload: { name } }),
  skipToDashboard: () => ({ type: actionTypes.SkipToDashboard }),
  saveUserData: (user) => ({ type: actionTypes.SaveUserData, payload: { user } }),
  SaveOrganizationDetails: (details) => ({ type: actionTypes.SaveOrganizationDetails, payload: details }),
};

export { actionTypes, actions, reducer }